import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Formik } from "formik";
import { AnchorLink } from "gatsby-plugin-anchor-links";
// import { Rating } from "react-simple-star-rating";

import { capturePosthogEvent, capturePosthogLink } from "../../utilities/index";
import { posthogEvents } from "@col-care/common/utilities/constants";
import {
  priceIdMap,
  idMapNumeric,
} from "../../providers/iheartjane/jane-embed";
import ProductCategoryForm from "./ProductCategoryForm";
import CloseIconBlack from "../../elements/icons/CloseIconBlack";

import HitImage from "../product-category-hit-image";

import * as styles from "./productcategory.module.css";

const specialPriceTypes = [
  "special_price_each",
  "special_price_eighth_ounce",
  "special_price_half_gram",
  "special_price_half_ounce",
  "special_price_ounce",
  "special_price_gram",
  "special_price_quarter_ounce",
];

const ProductCategoryHit = (props) => {
  const hit = props.hit;
  const thisStore = props.thisStore;

  if (!hit) {
    return null;
  }

  const posthog = props?.posthog;
  const cartData = props?.cartData;
  const [currentItem, setCurrentItem] = useState(null);
  // const [currentHit, setCurrentHit] = useState(null);
  const [currentQty, setCurrentQty] = useState(1);
  const [showItemAttrs, setShowItemAttrs] = useState(false);
  const [weightSelectionVisible, setWeightSelectionVisible] = useState(false);

  // Function to toggle the visibility of weight selection
  const toggleWeightSelection = () => {
    setWeightSelectionVisible(!weightSelectionVisible);
  };
  useEffect(() => {
    return () => {
      props.tileCloseFunctions[0] = null;
      delete props.tileCloseFunctions[0];
      props.checkPlusTimeoutFunctions[0] = null;
      delete props.checkPlusTimeoutFunctions[0];
    };
  }),
    [];

  let tagClass = "";
  if (hit.category === "cbd") {
    tagClass = "greenClass";
  } else if (hit.category === "sativa") {
    tagClass = "whiteClass";
  } else if (hit.category === "hybrid") {
    tagClass = "kind_type";
  } else if (hit.category === "indica") {
    tagClass = " blueClass";
  }
  const theLink = `/shop/${hit.custom_product_type}/${hit.unique_slug}`;
  const renderSalesTag = () => {
    if (hit?.special_id === 1493653) {
      // Do not render the sales tag if special_id is 1493653
      return null;
    }

    let salesTagRendered = false;

    for (const priceType of specialPriceTypes) {
      const priceData = props.hit[priceType];

      if (priceData?.discount_amount && !salesTagRendered) {
        salesTagRendered = true;

        return (
          <div className={`${styles.sales__tag} ${styles.sales__tag__red}`}>
            <span className={styles.sales__tag_text_container}>
              {priceData.discount_percent !== "0.0"
                ? `${parseFloat(priceData.discount_percent).toFixed(0)}%`
                : `$${parseFloat(priceData.discount_amount).toFixed(0)}`}
              <span className={styles.sales__tag_text}>OFF!</span>
            </span>
          </div>
        );
      }
    }

    return null;
  };
  function renderQuantityValue(hit) {
    if (hit.amount) {
      if (hit.kind === "pre-roll") {
        if (hit.amount.includes("pk")) {
          return <span>{hit.amount}</span>;
        } else {
          const quantity =
            hit.net_weight_grams === parseFloat(hit.amount)
              ? 1
              : Math.ceil(hit.quantity_value / parseFloat(hit.amount));
          return <span>{quantity === 0 ? "each" : `${quantity}pk`}</span>;
        }
      } else {
        return <span>{hit.amount}</span>;
      }
    } else {
      return <span>{idMapNumeric[_item]}</span>;
    }
  }
  const renderSpecialPrice = (priceType) => {
    const isBundle = hit?.applicable_special_types?.indexOf("bundle") > -1;
    const color = isBundle ? "#7ebd5a" : null;

    // Check if any of the special price types have a discount amount
    const specialPriceWithData = specialPriceTypes.find((type) => {
      const priceData = props.hit[type];
      return priceData?.discount_amount;
    });

    // If a special price with data is found, render it
    if (specialPriceWithData) {
      const priceData = props.hit[specialPriceWithData];
      const price = `${parseFloat(priceData.price).toFixed(2)}`;
      const mobilePrice = window.innerWidth <= 949 ? price.slice(0, -3) : price;
      return (
        <div className={styles.item_price_group}>
          <div>
            <span className={styles.item_price_red}>
              ${parseFloat(priceData.discount_price).toFixed(2)}/
              <span className={styles.item_weight_grams}>
                {renderQuantityValue(hit)}
              </span>
            </span>
          </div>
          <div>
            <span className={styles.item_price_crossout}>${mobilePrice}</span>
          </div>
        </div>
      );
    }

    // If no special price with data is found, render the regular price
    return (
      <div style={{ color }}>
        ${props.hit.sort_price.toFixed(2)}/
        <span className={styles.item_weight_grams}>
          {renderQuantityValue(hit)}
        </span>
      </div>
    );
  };

  let _item;
  const items = hit.available_weights[0]
    ? hit.available_weights
    : hit.inventory_potencies.map((v) => v.price_id);

  items.map(function (item) {
    if (hit[`price_${priceIdMap[item]}`] && !_item) {
      _item = priceIdMap[item];
    }
  });

  const handleSubmit = (values, actions) => {};

  const handleItemClick = (item) => {
    console.log({ handleItemClick: item });
    if (!showItemAttrs) {
      setCurrentItem(_item);
      setShowItemAttrs(true);
      toggleWeightSelection();

      capturePosthogEvent({
        eventName: posthogEvents.PRODUCT_TILE_DRAWER_OPENED,
        title: "Product category card Name: " + item.name,
        databag: { cartData },
        posthog,
      });

      props.tileCloseFunctions[0] && props.tileCloseFunctions[0]();

      props.tileCloseFunctions[0] = () => {
        setShowItemAttrs(false);
      };

      clearTimeout(props.checkPlusTimeoutFunctions[0]);
      props.checkPlusTimeoutFunctions[0] = null;
      delete props.checkPlusTimeoutFunctions[0];
    } else {
      const thisObj = {};
      thisObj[theLink] = true;
      props.updateCheckPlusState(thisObj);
      console.info(
        `Posthog event -> ${posthogEvents.PRODUCT_TILE_DRAWER_OPENED}`
      );
      posthog?.capture(posthogEvents.PRODUCT_TILE_DRAWER_OPENED);

      props.checkPlusTimeoutFunctions[0] = setTimeout(() => {
        setShowItemAttrs(false);
        props.tileCloseFunctions[0] = null;
        delete props.tileCloseFunctions[0];
        thisObj[theLink] = false;
        props.updateCheckPlusState(thisObj);
      }, 3000);

      const itemPrice = hit?.[`price_${currentItem}`] ?? hit.sort_price;
      const itemSpecial =
        hit?.[`special_price_${currentItem}`] !== undefined
          ? hit[`special_price_${currentItem}`]
          : hit.sort_price;

      const maxQtyForItem =
        hit[`max_cart_quantity_${currentItem}`] || hit.max_cart_quantity;

      const cartObj = {
        productId: hit.product_id,
        priceId: currentItem,
        count: currentQty,
        photo: hit?.image_urls[0],
        name: hit.name,
        brand: hit.brand,
        type: hit.type,
        maxQty: maxQtyForItem,
        price: parseFloat(itemPrice),
        itemSpecial: itemSpecial,
        specialTypes: hit.applicable_special_types,
        specialIds: hit.applicable_special_ids,
        bundleSpecialIds: hit.applicable_bundle_special_ids,
        brandSpecialIds: hit.applicable_brand_special_ids,
        bulkSpecialIds: hit.applicable_bulk_special_ids,
        pathname: `/shop/${hit.custom_product_type}/${hit.unique_slug}`,
        timestamp: Date.now(),
        max_qty: {
          qty: hit.max_cart_quantity,
          qty_each: hit.max_cart_quantity_each,
          qty_eighth_ounce: hit.max_cart_quantity_eighth_ounce,
          qty_gram: hit.max_cart_quantity_gram,
          qty_half_gram: hit.max_cart_quantity_half_gram,
          qty_half_ounce: hit.max_cart_quantity_half_ounce,
          qty_ounce: hit.max_cart_quantity_ounce,
          qty_quarter_ounce: hit.max_cart_quantity_quarter_ounce,
          qty_two_gram: hit.max_cart_quantity_two_gram,
        },
      };

      props.doAddToCart(cartObj);
      props.setShowCartModal(true);

      capturePosthogEvent({
        eventName: posthogEvents.ADD_TO_CART_MODAL_OPENED,
        posthog,
        databag: { cartData },
      });
    }
  };

  const navigatePDP = (link, title) => {
    navigate(link);
    capturePosthogLink({
      eventName: posthogEvents.PRODUCT_DETAILS_LINK_CLICKED,
      title: title,
      to: link,
      posthog,
    });
  };

  // console.log("hit", hit);

  // Calculate the total price based on the current quantity and price
  const totalPrice = parseFloat(
    currentQty *
      (hit[`special_price_${currentItem}`]?.discount_price ||
        hit[`price_${currentItem}`])
  );

  // If the totalPrice is NaN, use the sort_price instead
  const finalPrice = isNaN(totalPrice) ? hit.sort_price : totalPrice;

  return (
    <article className={styles.item_card}>
      <button
        style={{
          position: "absolute",
          top: "4px",
          right: 0,
          background: "transparent",
          border: "none",
          cursor: "pointer",
          zIndex: "80",
          display: showItemAttrs ? "inherit" : "none",
        }}
        aria-label="Dismiss"
        key={`product-category-dismiss-add-to-cart-${theLink}`}
        type="button"
        onClick={(e) => {
          setShowItemAttrs(false);
          capturePosthogEvent({
            eventName: posthogEvents.PRODUCT_TILE_DRAWER_CLOSED,
            title: "Product category card, Product Name: " + hit.name,
            posthog,
            databag: { cartData },
          });
        }}
      >
        <CloseIconBlack color="#375542" />
      </button>
      {props.checkPlusState[theLink] === true && !showItemAttrs && (
        <div className="item__center">
          <div className="item__bought">
            <div
              className="js-toggle-state c-button-reset c-plus-to-check"
              data-state="active"
            >
              <span className="c-plus-to-check__text">Add</span>
            </div>
          </div>
        </div>
      )}

      <div className={styles.side}>
        <div
          // className="item__image"
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            navigatePDP(theLink, hit.name);
          }}
        >
          <HitImage className="item__image" hit={hit} />
          {props.hit.applicable_special_types.indexOf("bundle") > -1 && (
            <div className={`${styles.sales_tag_green}  ${styles.sales__tag}`}>
              <span className={styles.sales__tag_text_mix_container}>
                MIX +<span className={styles.sales__tag_text_mix}> MATCH</span>
              </span>
            </div>
          )}
          {renderSalesTag()}
        </div>
      </div>
      <div className={styles.side}>
        <div className={styles.product_text_container}>
          <div
            className={styles.group__text}
            onClick={() => navigatePDP(theLink, hit.name)}
          >
            <div>
              <div className="item__brands">{hit.brand}</div>
              <div className="item__header">{hit.name}</div>
              {hit.brand_subtype && (
                <div className="item__thc">{hit.brand_subtype}</div>
              )}

              {hit.aggregate_rating > 0 && hit.review_count > 0 && (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className={styles.item__rating}
                >
                  <AnchorLink
                    title="Product Reviews"
                    to={theLink + "/#product-reviews"}
                  >{`${hit.review_count} Review${
                    hit.review_count > 1 ? "s" : ""
                  }`}</AnchorLink>
                </div>
              )}
              {((hit.percent_thc !== undefined && hit.percent_thc !== null) ||
                (hit.percent_cbd !== undefined &&
                  hit.percent_cbd !== null)) && (
                <div className="item__thc">
                  {hit.percent_thc && ` THC ${hit.percent_thc}%`}
                  {hit.percent_cbd && ` CBD ${hit.percent_cbd}%`}
                </div>
              )}
            </div>

            <div className="item__type">
              <span className={styles.kind_type}> {hit.kind}</span>
              {hit.category && hit.custom_product_type !== "gear" && (
                <span className={tagClass} style={{ color: "#fff" }}>
                  {hit.category}
                </span>
              )}
            </div>
          </div>

          <div className="item__price">{renderSpecialPrice()}</div>
          {props.hit.special_title && (
            <div
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                const special_id = props.hit?.applicable_special_ids[0] || null;
                props.setShowSpecialModal(true);
                props.setCurrentSpecial(props.specialsLookup[special_id]);
              }}
              className={styles.special_title}
            >
              {props.hit.special_title}
            </div>
          )}
        </div>

        <div
          className={`${styles.weight_selection} ${
            weightSelectionVisible ? styles.weight_selection_visible : ""
          }`}
          style={{ display: showItemAttrs ? `flex` : `none` }}
        >
          <div className="item__brands" id={styles.mobile_show}>
            {hit.brand}
          </div>
          <div
            style={{ marginBottom: "20px" }}
            className="item__header "
            id={styles.mobile_show}
          >
            {hit.name}
          </div>
          <Formik
            initialValues={{ quantity: { currentQty } }}
            onSubmit={handleSubmit}
            validate={(values) => ({})}
          >
            {(props) => {
              return (
                <ProductCategoryForm
                  currentHit={hit}
                  currentItem={currentItem}
                  currentQty={currentQty}
                  setCurrentItem={setCurrentItem}
                  setCurrentQty={setCurrentQty}
                  {...props}
                />
              );
            }}
          </Formik>
          <div className={styles.add_to_cart_button_container}>
            <button
              className={styles.add_to_cart_button}
              id="decrease-quantity-button"
              style={{
                cursor: currentQty === 1 ? "not-allowed" : "pointer",
                opacity: currentQty === 1 ? "0.5" : "1",
              }}
              onClick={() => {
                if (currentQty > 1) {
                  setCurrentQty(currentQty - 1);
                  if (posthog) {
                    console.info(
                      `Posthog event -> ${posthogEvents.ITEM_QUANTITY_DECREASED}`
                    );
                    posthog?.capture(posthogEvents.ITEM_QUANTITY_DECREASED, {
                      $EventDetails: {
                        store: thisStore.content.id,
                      },
                    });
                  }
                }
              }}
            >
              -
            </button>
            <div className={styles.count}>{currentQty}</div>
            <button
              className={styles.add_to_cart_button}
              id="increase-quantity-button"
              onClick={() => {
                const maxQtyForItem =
                  hit[`max_cart_quantity_${currentItem}`] ||
                  hit.max_cart_quantity;
                if (currentQty < maxQtyForItem) {
                  setCurrentQty(currentQty + 1);
                  console.info(
                    `Posthog event -> ${posthogEvents.ITEM_QUANTITY_INCREASED}`
                  );
                  posthog?.capture(posthogEvents.ITEM_QUANTITY_INCREASED, {
                    $EventDetails: {
                      store: thisStore.content.id,
                    },
                  });
                }
              }}
            >
              +
            </button>
          </div>
        </div>
        <div
          className="item__add_to_cart"
          onClick={() => handleItemClick(hit)}
          id="add-to-cart-button"
          style={{
            color: "#fff",
            textTransform: "uppercase",
            fontFamily: "AvenirNextMedium",
            textAlign: "center",
            marginTop: "10px",
            cursor: "pointer",
          }}
        >
          <span
            style={{
              position: "relative",
              top: "17px",
            }}
          >
            {showItemAttrs
              ? `Add to Cart $${parseFloat(finalPrice).toFixed(2)}`
              : hit.available_weights.length <= 1
              ? `Choose Quantity`
              : `Choose Weight`}
          </span>
        </div>
        {/*<div className="item__thc">
          THC {hit.percent_tch} CBD {hit.percent_cbd}*
        </div>
        <div className="item__price"> ${hit.sort_price}</div>
     
      {/*<br />
        <b>Brand:</b>
         <img
          style={{ width: "100%", height: "270px" }}
          src={hit?.image_urls[0]}
        />

        <br />
        <b>Type:</b>
        <p>{hit.custom_product_subtype}</p>
        <div className="item__subtype">{hit.custom_product_subtype}</div>
        <br />
      */}
      </div>
    </article>
  );
};

export default ProductCategoryHit;
