import React from "react";

import {
  priceIdMap,
  idPriceMap,
  priceIdMapNumeric,
} from "../../providers/iheartjane/jane-embed";

import * as tgsStyles from "./variants/productcategory-tgs.module.css";

const ProductCategoryForm = (props) => {
  //console.log("ProductCategoryForm props", props);
  let styles;

  switch (props.variant) {
    case "tgs":
    default:
      styles = tgsStyles;
      break;
  }

  return (
    <form onSubmit={props.handleSubmit}>
      {(() => {
        return (
          <>
            <span
              className="weight-container-section"
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                padding: "0px 20px",
                justifyContent: "center",

                //display: "grid",
                //gridTemplateColumns: "repeat(2, 1fr)",
                //gridColumnGap: "10px",
                // gridRowGap: "10px",
              }}
            >
              {getAttributeOptions(
                props.currentHit,
                props.currentItem,
                props.setCurrentItem,
                props.setCurrentQty
              )}
            </span>
          </>
        );
      })()}
    </form>
  );
};
export default ProductCategoryForm;

const getAttributeOptions = (
  currentHit,
  currentItem,
  setCurrentItem,
  setCurrentQty
) => {
  const options = [];
  if (currentHit?.available_weights?.length === 1) {
    const weight = currentHit.available_weights[0];
    options.push(
      <div
        key="single-weight"
        style={{
          padding: "10px 0px",
          fontSize: "14px",
          color: "#375542",
          textTransform: "uppercase",
        }}
      >
        <strong>Weight:</strong>{" "}
        {currentHit.amount ? (
          <span>{currentHit.amount}</span>
        ) : (
          <span>{priceIdMapNumeric[weight]}</span>
        )}
      </div>
    );
  } else {
    // this could need tweaking if we run into unreg product w/ attributes
    currentHit?.available_weights.map((v, k) => {
      const priceKey = `price_${priceIdMap[v]}`;
      const specialPriceKey = `special_price_${priceIdMap[v]}`;

      const itemPrice = currentHit[priceKey];
      const itemSpecialPrice = currentHit[specialPriceKey];

      const displayedPrice = itemSpecialPrice?.discount_price
        ? parseFloat(itemSpecialPrice.discount_price).toFixed(2)
        : parseFloat(itemPrice).toFixed(2);

      options.push(
        <div
          id={`attribute-options-${priceIdMap[v]}`}
          key={`attribute-options-${k}`}
          onClick={() => {
            setCurrentQty(1);
            setCurrentItem(priceIdMap[v]);
          }}
          className="weight_selection"
          style={{
            border:
              priceIdMap[v] === currentItem
                ? "3px solid #7EBD5A"
                : "1px solid #375542",
            padding: "10px 0px",
            cursor: "pointer",
            color:
              priceIdMap[v] === currentItem
                ? "#7EBD5A"
                : "rgba(55, 85, 66, .7)",
            fontFamily: "AvenirNextBold",
            textAlign: "center",
            borderRadius: "60px",
            flexBasis: "47%",
            boxSizing: "border-box",
          }}
        >
          <div>
            {" "}
            {currentHit.amount ? (
              <span>{currentHit.amount}</span>
            ) : (
              <span>{priceIdMapNumeric[v]}</span>
            )}
          </div>
          <div>${displayedPrice}</div>
        </div>
      );
    });
  }
  return options;
};
