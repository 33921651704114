import React from "react";
import { useInstantSearch } from "react-instantsearch";

const NoResultsBoundary = ({ children, fallback }) => {
  const { results } = useInstantSearch();

  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned yet.
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    );
  }

  return children;
};

export default NoResultsBoundary;

export function NoResults() {
  const { indexUiState } = useInstantSearch();

  return (
    <div>
      <p>
        No results found. Please clear your filters and try again.
      </p>
    </div>
  );
}
