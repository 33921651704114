import React, {
  useReducer,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import queryString from "query-string";
import {
  orderBy as _orderBy,
  isFinite as _isFinite,
  noop as _noop,
  isEqual as _isEqual,
  capitalize as _capitalize,
} from "lodash";
import debounce from "lodash/debounce";

import { navigate, Link } from "gatsby";
import {
  InstantSearch,
  InfiniteHits,
  Configure,
  SortBy,
  SearchBox,
  useClearRefinements,
} from "react-instantsearch";
import "react-multi-carousel/lib/styles.css";
import moment from "moment";

import ProductCategoryHit from "./ProductCategoryHit";
import FilterIcon from "./FilterIcon";
import CloseIcon from "./CloseIcon";
import { getSlug } from "../../helpers";
import {
  isActiveDateRange,
  capturePosthogLink,
  capturePosthogEvent,
} from "../../utilities/index";
import {
  posthogEvents,
  instantSearchSortOptions,
} from "../../utilities/constants";
import FilterContent from "./filter-content";
import CustomRefinementList from "./CustomRefinementList";
import NoResultsBoundary, { NoResults } from "./no-results-boundary";

import * as styles from "./productcategory.module.css";
import "./facets.css";
import "./algolia.css";
import { GhubLink } from "../../common";
const searchIndexName = "menu-products-production";

const tileCloseFunctions = [];
tileCloseFunctions[0] = () => {
  _noop();
};
const checkPlusTimeoutFunctions = [];
checkPlusTimeoutFunctions[0] = () => {
  _noop();
};

let specialsLookup = {},
  specialsTree = {};

const stateMapping = {
  stateToRoute(uiState) {
    const indexUiState = uiState[searchIndexName];
    return {
      query: indexUiState?.query,
      page: indexUiState?.page,
      sortBy: indexUiState?.sortBy,
      category: indexUiState?.refinementList?.root_types,
      strain_type: indexUiState?.refinementList?.category,
      brand: indexUiState?.refinementList?.brand,
      brand_subtype: indexUiState?.refinementList?.brand_subtype,
      special_id: indexUiState?.refinementList?.applicable_special_ids,
      bucket_price: indexUiState?.refinementList?.bucket_price,
    };
  },
  routeToState(routeState) {
    return {
      [searchIndexName]: {
        query: routeState?.query,
        page: routeState?.page,
        sortBy: routeState?.sortBy,
        refinementList: {
          root_types: routeState?.category,
          category: routeState?.strain_type,
          brand: routeState?.brand,
          brand_subtype: routeState?.brand_subtype,
          applicable_special_ids: routeState?.special_id,
          bucket_price: routeState?.bucket_price,
        },
      },
    };
  },
};

const ProductCategory = (props) => {
  const parsed = queryString.parse(props.location.search);
  const posthog = props?.posthog;
  const campaigns = props.campaigns;

  const [showCartModal, setShowCartModal] = useState(false);
  const [showSpecialModal, setShowSpecialModal] = useState(null);
  const [isActive, setActive] = useState(false);
  const [showLocationUpdateModal, setShowLocationUpdateModal] = useState(null);
  const [instantsearchError, setInstantsearchError] = useState(false);
  const [currentSpecial, setCurrentSpecial] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [instantSearchState, setInstantsearchState] = useState({
    sortBy: parsed.sortBy ?? "",
    category: parsed.strain_type,
    brand_subtype: parsed.brand_subtype,
    special_id: parsed.applicable_special_ids,
    range: parsed.bucket_price,
    brand: parsed.brand,
  });

  useEffect(() => {
    // Check if the class indicating no more hits is present
    const observer = new MutationObserver(() => {
      const noMoreHitsElement = document.querySelector(
        ".ais-InfiniteHits-loadMore--disabled"
      );
      if (noMoreHitsElement) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });

    observer.observe(document.body, {
      subtree: true,
      childList: true,
      attributes: true,
    });

    return () => observer.disconnect();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    // Set the timezone offset for US Mountain Time (GMT-7)
    moment.updateLocale("en", {
      // Use a fixed offset of -7 hours for Mountain Time (GMT-7)
      // You may need to adjust this offset based on DST (Daylight Saving Time)
      // This is a simplified approach and may not accurately handle all cases
      // For more accurate timezone handling, consider using a dedicated library like moment-timezone
      utcOffset: -7 * 60,
    });
  }, []);

  const debouncedSearch = debounce(async (query) => {
    if (query) {
      setInstantsearchState((oldState) => {
        return { ...oldState, query: query };
      });
      capturePosthogEvent({
        eventName: posthogEvents.INSTANTSEARCH_INTERACTION,
        title: "Searched for text " + query,
        databag: {
          cartData,
        },
        posthog,
      });
    }
  }, 500);

  const handleInstantsearchState = useCallback(
    (state) => {
      const newState = state?.[searchIndexName] ?? null;
      const category = newState?.refinementList?.category;
      const brand_subtype = newState?.refinementList?.brand_subtype;
      const brand = newState?.refinementList?.brand;
      const range = newState?.range;

      // if (newState) {
      //   console.log("setInstantsearchState changed");
      // }

      /**
       * Capture event on search */
      if (newState?.query && newState.query !== instantSearchState?.query) {
        debouncedSearch(newState?.query);
      }

      /**
       * Capture event for category - STRAIN TYPE */
      if (
        category &&
        JSON.stringify(category) !==
          JSON.stringify(instantSearchState?.category)
      ) {
        setInstantsearchState((oldState) => {
          return { ...oldState, category: category };
        });
        capturePosthogEvent({
          eventName: posthogEvents.INSTANTSEARCH_INTERACTION,
          title: "Selected category " + JSON.stringify(category),
          posthog,
          databag: {
            cartData: cartData,
          },
        });
      }

      /**
       * Capture event for SUB-CATEGORY */
      if (
        brand_subtype &&
        JSON.stringify(brand_subtype) !==
          JSON.stringify(instantSearchState?.brand_subtype)
      ) {
        setInstantsearchState((oldState) => {
          return { ...oldState, brand_subtype: brand_subtype };
        });
        capturePosthogEvent({
          eventName: posthogEvents.INSTANTSEARCH_INTERACTION,
          title: "Selected brand subtype " + JSON.stringify(brand_subtype),
          posthog,
          databag: {
            cartData: cartData,
          },
        });
      }

      /**
       * Capture event for Brand */
      if (
        brand &&
        JSON.stringify(brand) !== JSON.stringify(instantSearchState?.brand)
      ) {
        setInstantsearchState((oldState) => {
          return { ...oldState, brand: brand };
        });
        capturePosthogEvent({
          eventName: posthogEvents.INSTANTSEARCH_INTERACTION,
          title: "Selected brands " + JSON.stringify(brand),
          posthog,
          databag: {
            cartData: cartData,
          },
        });
      }

      /**
       * Capture event for category - STRAIN TYPE */
      if (newState?.sortBy && newState.sortBy !== instantSearchState?.sortBy) {
        setInstantsearchState((oldState) => {
          return { ...oldState, sortBy: newState.sortBy };
        });
        capturePosthogEvent({
          eventName: posthogEvents.INSTANTSEARCH_INTERACTION,
          title: "Sort by" + instantSearchSortOptions?.[newState.sortBy],
          posthog,
          databag: {
            cartData: cartData,
          },
        });
      }

      /**
       * Capture event for Price Range */
      if (range && range !== instantSearchState?.range) {
        setInstantsearchState((oldState) => {
          return { ...oldState, range: range };
        });
        capturePosthogEvent({
          eventName: posthogEvents.INSTANTSEARCH_INTERACTION,
          title: "Price range selected " + JSON.stringify(range),
          posthog,
          databag: {
            cartData: cartData,
          },
        });
      }
    },
    [setInstantsearchState, instantSearchState]
  );

  const initialCheckPlusState = {};

  const [checkPlusState, updateCheckPlusState] = useReducer(
    (state, updates) => ({ ...initialCheckPlusState, ...updates }),
    initialCheckPlusState
  );

  const routing = {
    router: props.searchHistory,
    stateMapping: stateMapping,
  };
  const recSpecials = props.specials?.rec;
  const medSpecials = props.specials?.med;

  const recSpecialsFiltered = useMemo(() => {
    return (
      recSpecials?.data?.specials?.filter((item, index) => {
        return (
          isActiveDateRange(item.start_date, item.end_date) && item.enabled
        );
      }) || null
    );
  }, [recSpecials]);

  const medSpecialsFiltered = useMemo(() => {
    return (
      medSpecials?.data.specials?.filter((item, index) => {
        return (
          isActiveDateRange(item.start_date, item.end_date) && item.enabled
        );
      }) || null
    );
  }, [medSpecials]);

  const Modal = props.modal;
  const Button = props.button;
  const actions = props.actions;

  const cartData = props.cartData;
  const locationData = props.locationData;
  const locations = props.locations;
  const location = props.location;

  const locationsByStoreId = useMemo(() => {
    return locations
      ? locations.reduce((a, v) => {
          a[v.content.vendor_integrations.jane_rec_id] = v;
          return a;
        }, {})
      : {};
  }, [locations]);

  const locationsById = useMemo(() => {
    return locations
      ? locations.reduce((a, v) => {
          a[v.content.id] = v;
          return a;
        }, {})
      : {};
  }, [locations]);

  specialsTree = useMemo(() => {
    const products = {},
      brands = {};
    const specialTypes = ["rec", "med"];

    specialTypes.map((type) => {
      if (props.specials && props.specials[type]) {
        props.specials[type].data.specials.reduce((a, v, k) => {
          if (v.enabled) {
            specialsLookup[v.id] = v;
            if (v.conditions.product?.included_product_ids) {
              v.conditions.product.included_product_ids.map((id) => {
                if (!products[id]) {
                  products[id] = [];
                }
                if (!products[id][type]) {
                  products[id][type] = [];
                }
                products[id][type].push(k);
              });
            }
            if (v.conditions.product?.brands) {
              v.conditions.product.brands.map((name) => {
                if (!brands[name]) {
                  brands[name] = [];
                }
                if (!brands[name][type]) {
                  brands[name][type] = [];
                }
                brands[name][type].push(k);
              });
            }
          }
        });
      }
    });

    return { products: products, brands: brands };
  }, [recSpecials, medSpecials, props.path]);

  const storeId = props.path ? props.path.split("/")[2] : null;
  const thisStore = storeId ? locationsById[storeId] : null;
  const currentStore = props.cartData.store.janeId
    ? locationsByStoreId[props.cartData.store.janeId]
    : null;

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => setIsOpen(!isOpen);

  return (
    <>
      <section>
        <div className="algolia-container" id="category-page">
          {props.storeId && props.siteConfigData?.env?.jane?.algolia_key && (
            <div className={styles.tgs_hero_inner}>
              <h1>{thisStore?.content?.nickname}</h1>
              {/*<p>Please enjoy our products responsibly!</p> */}
              <div className={styles.heroBtnContainer}>
                {thisStore && cartData.store.id !== thisStore?.content?.id && (
                  <Button
                    id="shop-at-location"
                    theme="tgsButtonHero"
                    label="Shop This Location"
                    onClick={(e) => {
                      if (cartData.store.id === null) {
                        cartData.store.id = locationData.location.location_id;
                        cartData.store.name = locationData.title;
                        cartData.store.janeId =
                          locationData.location.vendor_integrations.jane_rec_id;
                        cartData.products = [];
                        cartData.productsCount = 0;
                        actions.session.doSetCartData(cartData);
                      } else {
                        setShowLocationUpdateModal(locationData.location);
                      }
                    }}
                  />
                )}
                <Button
                  theme="tgsButtonHero"
                  label="View Details"
                  onClick={(e) => {
                    const link = `/locations/${thisStore?.content?.id}`;
                    navigate(link);
                    capturePosthogLink({
                      eventName: posthogEvents.LOCATION_LINK_CLICKED,
                      title: "View Details",
                      to: link,
                      posthog,
                    });
                  }}
                />
                {thisStore && cartData.store.id === thisStore?.content?.id && (
                  <Button
                    theme="tgsTransparentGreen"
                    label="Change Location"
                    onClick={(e) => {
                      navigate(`/locations`);
                      capturePosthogLink({
                        eventName: posthogEvents.LOCATION_LINK_CLICKED,
                        title: "Change Location",
                        to: "/locations",
                        posthog,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {campaigns["420_2024"]?.feature_flag &&
            posthog.isFeatureEnabled(campaigns["420_2024"].feature_flag) &&
            getCampaignMarkup(campaigns, "420_2024")}
          {props.storeId &&
            props.siteConfigData?.env?.jane?.algolia_key &&
            (!!recSpecialsFiltered || !!medSpecialsFiltered) && (
              <div
                id="deals-container"
                className={styles.deals_container}
                onClick={() => {
                  setActive(!isActive);

                  if (posthog) {
                    capturePosthogEvent({
                      eventName: isActive
                        ? posthogEvents.SPECIALS_ACCORDION_CLOSED
                        : posthogEvents.SPECIALS_ACCORDION_OPENED,
                      title: isActive
                        ? posthogEvents.SPECIALS_ACCORDION_CLOSED
                        : posthogEvents.SPECIALS_ACCORDION_OPENED,
                      posthog,
                      databag: {
                        cartData: cartData,
                      },
                    });
                  }
                }}
              >
                <div className={styles.deals_header_container}>
                  <h3>Current Deals</h3>
                  <button
                    className={
                      !isActive ? `${styles.active}` : `${styles.inactive}`
                    }
                  />
                </div>
                <div
                  className={
                    isActive
                      ? `${styles.deals_info}`
                      : `${styles.deals_info_open}`
                  }
                >
                  {!!recSpecialsFiltered && (
                    <>
                      <h4>Recreational Specials</h4>
                      <ul>
                        {recSpecialsFiltered.map((val) => {
                          return (
                            <li key={getSlug(val.title)}>
                              {!!val.id &&
                              (!!val?.conditions?.product?.brands?.length ||
                                !!val?.conditions?.product?.included_product_ids
                                  ?.length) ? (
                                <Link
                                  to={`/shop/${thisStore?.content?.id}/specials?special_id=${val.id}`}
                                  onClick={() => {
                                    capturePosthogLink({
                                      eventName:
                                        posthogEvents.SPECIALS_LINK_CLICKED +
                                        " Recreational Specials",
                                      title: val.title,
                                      to: `/shop/${thisStore?.content?.id}/specials?special_id=${val.id}`,
                                      posthog,
                                    });
                                  }}
                                >
                                  {val.title}
                                </Link>
                              ) : (
                                val.title
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                  {!!medSpecialsFiltered && (
                    <>
                      {medSpecialsFiltered?.length > 0 && (
                        <h4>Medical Specials</h4>
                      )}
                      <ul>
                        {medSpecialsFiltered?.map((val) => {
                          return (
                            <li key={val.title}>
                              {!!val.id &&
                              (!!val?.conditions?.product?.brands?.length ||
                                !!val?.conditions?.product?.included_product_ids
                                  ?.length) ? (
                                <Link
                                  to={`/shop/${thisStore?.content?.id}/specials?special_id=${val.id}`}
                                  onClick={() => {
                                    capturePosthogLink({
                                      eventName:
                                        posthogEvents.SPECIALS_LINK_CLICKED +
                                        " Medical Specials",
                                      title: val.title,
                                      to: `/shop/${thisStore?.content?.id}/specials?special_id=${val.id}`,
                                      posthog,
                                    });
                                  }}
                                >
                                  {val.title}
                                </Link>
                              ) : (
                                val.title
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </div>
              </div>
            )}

          {props.storeId && props.siteConfigData?.env?.jane?.algolia_key && (
            <InstantSearch
              searchClient={props.searchClient}
              onStateChange={({ uiState, setUiState, ...rest }) => {
                // console.log({ txt: "onStateChange called", uiState, rest });
                if (instantsearchError) {
                  uiState[searchIndexName].query = null;
                  setInstantsearchError(false);
                }
                setUiState(uiState);
                handleInstantsearchState(uiState);
              }}
              indexName={searchIndexName}
              future={{
                preserveSharedStateOnUnmount: true,
              }}
              routing={routing}
              onSearchStateChange={(searchState) => {
                // Perform actions when search is performed
                // console.log("Search performed with query:", { searchState });
              }}
            >
              <div
                className={`Drawer__Container ${
                  isOpen && "Drawer__Container--isOpen"
                }`}
              >
                <div className="DrawerContents__Container ">
                  <FilterContent
                    specialsLookup={specialsLookup}
                    onToggleDrawer={toggleDrawer}
                  />

                  <div
                    className={styles.mobile__filter_close}
                    onClick={toggleDrawer}
                  >
                    <CloseIcon color="#375542" />
                  </div>
                </div>
              </div>
              <Configure
                hitsPerPage={21}
                filters={`store_id = ${props.storeId}`}
              />
              <div className={styles.search_panel__filter_container}>
                <SearchBox />
                <SortBy
                  items={[
                    {
                      label: "A - Z",
                      value: "menu-products-production",
                    },
                    {
                      label: "Price (asc)",
                      value: "menu-products-by-price-production",
                    },
                    {
                      label: "Price (desc)",
                      value: "menu-products-by-price-desc-production",
                    },
                    // { label: 'Sort: THC% Low to High', value: 'menu-products-by-thc-potency-desc-production' },
                    // { label: 'Sort: THC% High to Low', value: 'menu-products-by-thc-potency-asc-production' }
                  ]}
                />
                <div className={`${styles.hide_desktop} ${styles.width}`}>
                  <div className={styles.mobile__filter} onClick={toggleDrawer}>
                    <FilterIcon className={styles.filter__icon} />
                    Filters
                  </div>
                </div>
              </div>

              <div id={styles.sticky_cards}>
                <CustomRefinementList
                  location={props?.location}
                  attribute={"root_types"}
                  storeId={storeId}
                  posthog={posthog}
                />
              </div>

              <div className="search-panel">
                <FilterContent specialsLookup={specialsLookup} hide="true" />
                <div className="search-panel__results">
                  <NoResultsBoundary fallback={<NoResults />}>
                    <ErrorBoundary
                      instantsearchError={instantsearchError}
                      setInstantsearchError={setInstantsearchError}
                      posthog={posthog}
                    >
                      <InfiniteHits
                        showPrevious={true}
                        hitComponent={(hit) => {
                          return (
                            <>
                              <ProductCategoryHit
                                hit={hit}
                                tileCloseFunctions={tileCloseFunctions}
                                checkPlusState={checkPlusState}
                                updateCheckPlusState={updateCheckPlusState}
                                doAddToCart={props.actions.session.doAddToCart}
                                checkPlusTimeoutFunctions={
                                  checkPlusTimeoutFunctions
                                }
                                setShowSpecialModal={setShowSpecialModal}
                                setShowCartModal={setShowCartModal}
                                specialsTree={specialsTree}
                                specialsLookup={specialsLookup}
                                currentSpecial={currentSpecial}
                                setCurrentSpecial={setCurrentSpecial}
                                posthog={props?.posthog}
                                thisStore={thisStore}
                                cartData={cartData}
                                {...hit}
                              />
                            </>
                          );
                        }}
                      />
                      {showButton && (
                        <button
                          className="ais-InfiniteHits-loadMore"
                          onClick={scrollToTop}
                        >
                          Back to Top
                        </button>
                      )}
                    </ErrorBoundary>
                  </NoResultsBoundary>
                </div>
              </div>
            </InstantSearch>
          )}
        </div>
        <Modal
          buttonOpts={["tgssmall", "tgsTransparent"]}
          modalTitle={`Cart`}
          variant={`cart`}
          dismissModal={() => {
            setShowCartModal(false);
          }}
          extraClasses="modal-dialog-cart"
          hide={!showCartModal}
          currentStore={thisStore}
          shouldCloseOnOverlayClick={true}
          actions={actions}
          user={props.user}
          posthog={posthog}
        />

        <Modal
          buttonOpts={["tgssmall", "tgsTransparent"]}
          modalTitle={`Change Locations?`}
          variant={`location_update`}
          dismissModal={() => {
            setShowLocationUpdateModal(false);
          }}
          extraClasses="modal-dialog-location-update"
          hide={!showLocationUpdateModal}
          currentStore={currentStore}
          shouldCloseOnOverlayClick={false}
          showSpecialModal={showSpecialModal}
          actions={actions}
          location={showLocationUpdateModal}
        />

        <Modal
          buttonOpts={["tgssmall", "tgsTransparent"]}
          modalTitle={`Special`}
          variant={`product_specials`}
          dismissModal={() => {
            setShowSpecialModal(null);
          }}
          extraClasses="modal-dialog-special"
          hide={!showSpecialModal}
          thisStore={thisStore} // current store context
          currentStore={currentStore} // store being shopped at
          shouldCloseOnOverlayClick={true}
          actions={actions}
          currentSpecial={currentSpecial}
          setCurrentSpecial={setCurrentSpecial}
          location={location}
        />
      </section>
    </>
  );
};

export default ProductCategory;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    this.props.setInstantsearchError(true);
    this.setState({ error: true });
  }

  resetErrorBoundary() {
    this.props.setInstantsearchError(false);
    this.setState({ error: false });
  }

  render() {
    if (this.props.instantsearchError && this.state.error) {
      return (
        <div>
          <p>Something went wrong!</p>
          <TgsClearRefinements
            setInstantsearchError={this.props.setInstantsearchError}
            resetErrorBoundary={this.resetErrorBoundary}
            posthog={this.props.posthog}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

function TgsClearRefinements(props) {
  const { refine } = useClearRefinements(props);

  return (
    <button
      onClick={(e) => {
        refine("");
        let thisTimeout = setTimeout(() => {
          refine("");
          props.resetErrorBoundary();
          thisTimeout = null;
        }, 750);
        props.resetErrorBoundary();
        // On clear filter BUTTON ACTION
        capturePosthogEvent({
          eventName: posthogEvents.INSTANTSEARCH_INTERACTION,
          title: "Clear filter action performed",
          posthog,
          databag: {
            cartData: cartData,
          },
        });
      }}
    >
      Clear filters
    </button>
  );
}

const getCampaignMarkup = (campaigns, key) => {
  if (!campaigns[key]) {
    return null;
  }

  if (!campaigns[key].elements[0] && !campaigns[key].locations[0]) {
    return null;
  }

  return (
    <div className={styles.campaign_contatiner}>
      {campaigns[key]?.elements?.[0]?.banner?.heading && (
        <h4>{campaigns[key].elements[0].banner.heading}</h4>
      )}
      <GhubLink
        destination={
          campaigns[key]?.elements?.[0]?.banner?.calls_to_action?.cta_1?.href
        }
      >
        <img
          alt={
            campaigns[key]?.locations[0]?.file
              ? campaigns[key]?.locations[0].file.title
              : campaigns[key]?.markets[0].file.title
          }
          src={
            campaigns[key]?.locations[0]?.file
              ? campaigns[key]?.locations[0].file.url
              : campaigns[key]?.markets[0].file.url
          }
        />
      </GhubLink>
    </div>
  );
};
