import React, { useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import { navigate } from "gatsby";

import { getBoundActions } from "../state/actions";
import ProductCategory from "@col-care/common/blocks/product-category/ProductCategory";
import Modal from "../components/modal/Modal";
import Button from "../components/button/Button";
import { posthogEvents } from "@col-care/common/utilities/constants";
import {
  capturePosthogLink,
  getCurrentCampaigns,
} from "@col-care/common/utilities/index";

export default function ShopStore(props) {
  const store = useStore();
  const actions = getBoundActions(store.dispatch);
  const user = useSelector((state) => state.auth.currentUser);
  const _persist = useSelector((state) => state._persist);
  const location = useSelector((state) => state.router.location);
  const locationData = useSelector((state) => state.hydrate.locationData);
  const siteConfigData = useSelector((state) => state.hydrate.siteConfigData);
  const specials = useSelector((state) => state.hydrate.databagData.specials);
  const campaigns = useSelector((state) => state.hydrate.databagData.campaigns);

  const cartData = useSelector((state) => state.session.cartData);
  const posthog = props?.posthog;

  const segments = location.pathname ? location.pathname.split("/") : [];
  const thisStore = segments[2];

  const currentCampaigns = getCurrentCampaigns(thisStore, campaigns);

  const campaignsById = useMemo(() => {
    return currentCampaigns?.reduce((acc, campaign) => {
      acc[campaign.unique_id] = campaign;
      return acc;
    }, {});
  }, [campaigns]);

  // @todo: refactor redirects with redux middleware
  if (
    location.pathname &&
    location.pathname.indexOf("/shop/") === 0 &&
    segments[2] &&
    !segments[3] &&
    thisStore !== cartData.store.id
  ) {
    if (_persist?.rehydrated === true) {
      navigate("/locations");
      capturePosthogLink({
        eventName: posthogEvents.LOCATION_LINK_CLICKED,
        title: "Locations page redirect",
        to: "/locations",
        posthog,
      });
    }
    return null;
  } else if (
    !props.algoliaSearchClient ||
    !props.algoliaSearchClient.appId ||
    !_persist?.rehydrated
  ) {
    return null;
  } else {
    return (
      <>
        <ProductCategory
          actions={actions}
          user={user}
          cartData={cartData}
          storeId={cartData.store.janeId}
          modal={Modal}
          button={Button}
          locationData={locationData}
          locations={props.pageContext.databag.locations}
          location={location}
          path={location.pathname}
          siteConfigData={siteConfigData}
          searchClient={props.algoliaSearchClient}
          searchHistory={props.algoliaSearchHistory}
          specials={specials}
          posthog={posthog}
          campaigns={campaignsById}
        />
      </>
    );
  }
}
