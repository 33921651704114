// extracted by mini-css-extract-plugin
export var add__height = "productcategory-module--add__height--0f354";
export var add__to_cart = "productcategory-module--add__to_cart--ac0a7";
export var add_to_cart_button = "productcategory-module--add_to_cart_button--82ea1";
export var add_to_cart_button_container = "productcategory-module--add_to_cart_button_container--0d04e";
export var arrow__icon = "productcategory-module--arrow__icon--d7841";
export var campaign_contatiner = "productcategory-module--campaign_contatiner--d8199";
export var cart__icon = "productcategory-module--cart__icon--529f2";
export var count = "productcategory-module--count--c64de";
export var deals_container = "productcategory-module--deals_container--c0408";
export var deals_header_container = "productcategory-module--deals_header_container--79535";
export var deals_info = "productcategory-module--deals_info--f979f";
export var deals_info_open = "productcategory-module--deals_info_open--63667";
export var filter__icon = "productcategory-module--filter__icon--c42f7";
export var heroBtnContainer = "productcategory-module--heroBtnContainer--dc894";
export var hide_desktop = "productcategory-module--hide_desktop--508bf";
export var hide_mobile = "productcategory-module--hide_mobile--79ea1";
export var inactive = "productcategory-module--inactive--c4074";
export var item__brand_filter = "productcategory-module--item__brand_filter--fda28";
export var item__rating = "productcategory-module--item__rating--9fd6d";
export var item__specials_filter = "productcategory-module--item__specials_filter--09b5d";
export var item__sub_filter = "productcategory-module--item__sub_filter--e03d8";
export var item_card = "productcategory-module--item_card--8f7da";
export var item_price_crossout = "productcategory-module--item_price_crossout--ea7d7";
export var item_price_group = "productcategory-module--item_price_group--85591";
export var item_price_red = "productcategory-module--item_price_red--701df";
export var item_weight_grams = "productcategory-module--item_weight_grams--98942";
export var mobile__filter = "productcategory-module--mobile__filter--1da49";
export var mobile__filter_close = "productcategory-module--mobile__filter_close--33fb3";
export var mobile_show = "productcategory-module--mobile_show--93196";
export var open = "productcategory-module--open--3aa4e";
export var opensvg = "productcategory-module--opensvg--82b32";
export var product_location = "productcategory-module--product_location--e474e";
export var product_text_container = "productcategory-module--product_text_container--39bf8";
export var rheostat_container = "productcategory-module--rheostat_container--6f741";
export var sales__tag = "productcategory-module--sales__tag--47e1e";
export var sales__tag__red = "productcategory-module--sales__tag__red--f37a5";
export var sales__tag_text = "productcategory-module--sales__tag_text--77e36";
export var sales__tag_text_container = "productcategory-module--sales__tag_text_container--58c3c";
export var sales__tag_text_mix_container = "productcategory-module--sales__tag_text_mix_container--923bf";
export var sales_tag_green = "productcategory-module--sales_tag_green--09ff2";
export var search_panel__filter_container = "productcategory-module--search_panel__filter_container--928c3";
export var side = "productcategory-module--side--2e7c5";
export var slideInFromBottom = "productcategory-module--slideInFromBottom--b5d37";
export var special_title = "productcategory-module--special_title--7fcc4";
export var sticky_cards = "productcategory-module--sticky_cards--7cf42";
export var tgs_hero = "productcategory-module--tgs_hero--83551";
export var tgs_hero_inner = "productcategory-module--tgs_hero_inner--75532";
export var weight_selection = "productcategory-module--weight_selection--fcfc1";
export var weight_selection_visible = "productcategory-module--weight_selection_visible--bf633";
export var width = "productcategory-module--width--0570f";