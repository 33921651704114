import React from "react"; // eslint-disable-line

const ArrowIcon = ({ color, className, height }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.09766 6.75488L8.1958 10.853L12.2939 6.75488"
      stroke="#375542"
      strokeWidth="1.36605"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowIcon;
