import React from "react";
import { useRange } from "react-instantsearch";
import {
  orderBy as _orderBy,
  isFinite as _isFinite,
  capitalize as _capitalize,
} from "lodash";
import Rheostat from "rheostat";
import "rheostat/initialize";

const RangeSlider = (props) => {
  const state = useRange(props);
  const values = [
    _isFinite(state.start[0]) ? state.start[0] : state.range.min,
    _isFinite(state.start[1]) ? state.start[1] : state.range.max,
  ];

  const handleValuesUpdated = ({ values: [min, max] }) => {
    state.refine([min, max]);
  };

  return (
    <>
      <Rheostat
        min={state.range.min}
        max={state.range.max}
        values={values}
        onChange={() => {}}
        onValuesUpdated={handleValuesUpdated}
      />
      <div className="sider__number_container">
        <div className="slider__number">{`$${
          _isFinite(values[0]) ? values[0] : state.range.min
        }`}</div>
        <div className="slider__number ">{`$${
          _isFinite(values[1]) ? values[1] : state.range.max
        }`}</div>
      </div>
    </>
  );
};

export default RangeSlider;
