import React from "react"; // eslint-disable-line

const CloseIcon = ({ color, className }) => {
  const defaultColor = "#000"; // Default color to black if none provided
  const strokeColor = color || defaultColor; // Use provided color or default color

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={strokeColor}
      strokeWidth={4}
      role="presentation"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke="#202245"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#202245"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default CloseIcon;
