import React from "react"; // eslint-disable-line

const FilterIcon = ({ color, className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    stroke={color}
    strokeWidth={4}
    role="presentation"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_470_12882)">
      <path
        d="M2.5931 3.51196L8.35352 3.51196"
        stroke="white"
        strokeWidth="1.64583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6458 3.51196L17.4062 3.51196"
        stroke="white"
        strokeWidth="1.64583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.59375 10.0959L10 10.0959"
        stroke="white"
        strokeWidth="1.64583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2917 10.0959L17.4062 10.0959"
        stroke="white"
        strokeWidth="1.64583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5944 16.6799L6.70898 16.6799"
        stroke="white"
        strokeWidth="1.64583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 16.6799L17.4062 16.6799"
        stroke="white"
        strokeWidth="1.64583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.35352 1.04321L8.35352 5.98071"
        stroke="white"
        strokeWidth="1.64583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.291 7.6272L13.291 12.5647"
        stroke="white"
        strokeWidth="1.64583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.70898 14.2112L6.70898 19.1487"
        stroke="white"
        strokeWidth="1.64583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_470_12882">
        <rect
          width="19.75"
          height="19.75"
          fill="white"
          transform="translate(19.875 0.220947) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default FilterIcon;
